
import { getBoardCategory } from '@/api/boardCategory';
import {
  Vue,
  Component,
} from 'vue-property-decorator';
import Summernote from '@/components/summernote/index.vue';
import { uploadFile } from '@/api/attachedFile';
import { updateBusiness, addBusiness, getBusiness } from '@/api/business';
import { UserModule } from '@/store/user';

@Component({
  components: {
    Summernote,
  },
})
export default class extends Vue {
  mounted() {
    if (UserModule.roles.indexOf('ROLE_ADMIN') > -1 || UserModule.roles.indexOf('ROLE_b2ba2135-770c-461f-8939-932a7b94dff8') > -1) {
      this.setForm();
      this.getCategoryList();
    } else {
      this.$router.push({ name: 'Business' });
    }
  }

  private categoryList = [];

  private categoryList2 = [];

  private categoryList3 = [];

  private applyCheck = '';

  private form: any = {
    title: '',
    categoryUid: '',
    categoryUid2: '',
    applyCheck: 'y',
    content: '',
    institutionName: '',
    recruitStart: '',
    recruitEnd: '',
    supportFund: '',
    fileInfos: [],
    files: [],
    target: '',
  }

  private fileCountLimit = 5;

  private fileSizeLimit = 5

  private getCategoryList() {
    getBoardCategory('33b34a3c-b79c-43bd-8042-e364d0f560da').then((res) => {
      this.categoryList = res.data.children;
    });
    getBoardCategory('135f0245-97e2-4210-8317-08f01e0cf219').then((res) => {
      this.categoryList2 = res.data.children;
    });
    getBoardCategory('48d257cf-f358-4f2d-920a-404804aa0abb').then((res) => {
      this.categoryList3 = res.data.children;
    });
  }

  private setForm() {
    if (this.$route.params.businessIdx) {
      getBusiness(this.$route.params.businessIdx).then((res) => {
        this.form = res.data;
      });
    }
  }

  /* eslint-disable */
  private handleClickSubmit() {
    this.form.fileInfos.forEach((fileInfo: any) => {
      if (fileInfo.fileName.trim().length === 0) {
        alert('필요 첨부파일명을 입력하세요.');
        return;
      }
    });
    if (!this.form.recruitStart || !this.form.recruitEnd) {
      alert('접수일을 입력하세요.');
      return;
    }
    this.$validator.validate().then((valid: boolean) => {
      if (valid) {
        const loading = this.$loading.show();
        if (this.$route.params.businessIdx) {
          updateBusiness(this.$route.params.businessIdx, this.form).then(() => {
            alert('사업공고를 수정했습니다.');
            loading.hide();
            this.$router.push({ name: 'BusinessDetail' });
          }).catch(() => {
            alert('요청에 실패했습니다.');
            loading.hide();
          });
        } else {
          addBusiness(this.form).then(() => {
            alert('사업공고를 추가했습니다.');
            loading.hide();
            this.$router.push({ name: 'Business' });
          }).catch(() => {
            alert('요청에 실패했습니다.');
            loading.hide();
          });
        }
      }
    });
  }
  /* eslint-enable */

  private handleClickCancle() {
    if (this.$route.params.businessIdx) {
      this.$router.push({ name: 'BusinessDetail', params: { menuUid: this.$route.params.menuUid, businessIdx: this.$route.params.businessIdx } });
    } else {
      this.$router.push({ name: 'Business', params: { menuUid: this.$route.params.menuUid } });
    }
  }

  private handleChangeContents(contents: string) {
    this.form.content = contents;
  }

  private handleChangeFile(e: any, isImageUpload: boolean) {
    const file = e.target.files[0];
    if (this.fileSizeLimit * 1024 * 1024 < file.size) {
      alert(`최대 ${this.fileSizeLimit}MB 까지 업로드 가능합니다.`);
      return;
    }
    if (this.fileCountLimit <= this.form.files.length) {
      alert(`최대 ${this.fileCountLimit}개까지 업로드 가능합니다.`);
      return;
    }
    const formData = new FormData();
    formData.append('file', e.target.files[0]);
    uploadFile('business', formData).then((res) => {
      this.form.files.push({
        fileUid: res.data.uid,
        name: res.data.originalName,
      });
    });
  }

  private handleClickRemoveFile(index: number) {
    this.form.files.splice(index, 1);
  }

  private handleAddFileInfo() {
    this.form.fileInfos.push({ fileName: '', orderNum: 0, required: true });
  }

  private handleRemoveFileInfo(index: number) {
    this.form.fileInfos.splice(index, 1);
  }
}
